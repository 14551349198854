<template>
  <div class="wrap">
    <Navbar :title="$t('nav.orderDetails')" />
    <!-- <van-nav-bar :title="$t('nav.orderDetails')">
      <div class="nav_left_wrap" slot="left">
        <van-icon name="arrow-left" color="#fff" @click="$router.back()" />
      </div>
    </van-nav-bar> -->
    <div class="content" v-for="(item, index) in table" :key="index">
      <div class="left">{{ item.title }}</div>
      <div class="right" v-if="!item.showDice">{{ item.value }}</div>
      <div class="right" v-else-if="!!content.open && !!content.open.dwd">
        <div class="ball_wrap">
          <div class="ball" v-for="(item, index) in content.open.dwd" :key="index">{{ item }}</div>
        </div>
        <!-- <img v-for="item, index in content.open.dwd" :key="index" :src="require(`@/assets/icon/dice/${item}.png`)"
          alt=""> -->
      </div>
    </div>
  </div>
</template>

<script>
import { $post, $get } from "../../utils/request";
import { post } from "@/utils/newRequest";
import { NavBar, Toast, Icon } from "vant";
import { format } from "../../utils/tools";
import Navbar from "@/components/JNav"
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    Toast,
    Navbar
  },
  data() {
    return {
      content: {},
    };
  },
  created() {
    const { id } = this.$route.query;
    this.getData(id);
  },
  computed: {
    table() {
      return [
        {
          title: this.$t("orderDetails.title.time"),
          value: format(this.content.indate),
        },
        {
          title: this.$t("orderDetails.title.type"),
          value: this.content.typeCn,
        },
        {
          title: this.$t("orderDetails.title.expect"),
          value: this.content.expect,
        },
        {
          title: this.$t("orderDetails.title.method"),
          value: this.$t(this.content.methodCn),
        },
        {
          title: this.$t("orderDetails.title.result"),
          // value: this.content.openCode && this.$t(this.content.openCode.split("").join(" ")),
          value: !!this.content.open ? this.getResult(this.content.open['hz-hz-dxds']) : "",
          showDice: true
        },
        {
          title: this.$t("orderDetails.title.inType"),
          value: this.getCode(this.content.code)
        },
        {
          title: this.$t("orderDetails.title.odds"),
          value: this.content.odds && this.content.odds[this.content.method]
        },
        {
          title: this.$t("orderDetails.title.inPerPrice"),
          value: this.content.money,
        },
        {
          title: this.$t("orderDetails.title.inNum"),
          value: this.content.num,
        },
        {
          title: this.$t("orderDetails.title.winAmount"),
          value: this.content.winNum,
        }
      ];
    },
  },
  methods: {
    async getData(id) {
      try {
        const res = await post("/lottery/order", { id });
        // const { ret, msg, data } = res.data;
        const data = res;
        this.content = data.lists[0];
        console.log(data);
      } catch (error) {
      }
    },
    getStatusText(text) {
      switch (text) {
        case "大":
          return this.$t("open.big");
          break;
        case "小":
          return this.$t("open.small");
        case "单":
          return this.$t("open.single");
        case "双":
          return this.$t("open.double");
        default:
          return text;
      }
    },
    getCode(text) {
      if (!text) return;
      const reg = /[(dxds)]/;
      let arr = [];
      if (reg.test(this.content.method)) {
        arr = text.split("");
      } else {
        arr = [text]
      }
      const res = []
      arr.map(item => {
        res.push(this.getStatusText(item))
      })
      return res.join(' ')
    },
    getResult(data) {
      if (!data) return "";
      let res = ""
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const value = data[key];
          res += "\n" + this.getStatusText(value)
        }
      }
      return res;
    }
  },
};
</script>

<style lang="less" scoped>
.wrap {
  height: 100vh;
  background: #f4f8f9;

  /deep/ .van-nav-bar {
    background-color: #ff0000;
  }

  /deep/ .van-nav-bar__title {
    color: var(--textColor);
  }

  .ball_wrap {
    margin-left: calc(-4rem / 16);

    // display: flex;
    .ball {
      text-indent: 0;
      text-align: center;
      border-radius: 50%;
      width: calc(26rem / 16);
      line-height: calc(26rem / 16);
      height: calc(26rem / 16);
      margin: 1px;
      background: var(--theme);
      color: #000;
      display: inline-block;
      background-image: url("~@/assets/icon/game_ball.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  .content {
    display: flex;
    align-items: center;
    @border: 1px solid #ccc;
    border-bottom: @border;
    width: 100vw;

    >div {
      width: 50%;
      line-height: calc(32rem / 16);
      text-indent: calc(24rem / 16);
    }

    .left {
      font-size: calc(14rem / 16);
      border-right: @border;
    }

    .right {
      font-size: calc(16rem / 16);
      display: flex;
      align-content: center;

      >img {
        width: 20px;
        height: 20px;
        border: 1px solid #eee;
        margin-left: 10px;

        &:nth-of-type(1) {
          margin-left: 24px;
        }
      }
    }
  }
}
</style>